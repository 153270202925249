import {
  Checkbox,
  Col,
  Collapse,
  Image,
  Pagination,
  Row,
  Slider,
  Spin,
  Table,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import filterIconBlue from "../../assets/img/filterIconBlue.svg";
import leftArrow from "../../assets/img/leftArrow.svg";
import noDataIcon from "../../assets/img/noDataIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetProjectsDetailsComponents,
  actionGetProjectsDetailsIssues,
} from "../../store/services/projectService";

const ProjectDetail = () => {
  const [scored, setScored] = useState(500);
  const [currentPageForComponent, setCurrentPageForComponent] = useState(1);
  const [itemsPerPageForComponent, setItemsPerPageForComponent] = useState(10);
  const [currentPageForIssues, setCurrentPageForIssues] = useState(1);
  const [itemsPerPageForIssues, setItemsPerPageForIssues] = useState(10);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const projectId = state.projectId;
  const {
    getProjectsDetailsIssuesLoader,
    projectsDetailsIssues,
    getProjectsDetailsComponentsLoader,
    projectsDetailsComponents,
    totalProjectsComponent,
    totalProjectsIssues,
  } = useSelector((state) => state.project);
  const { components, severityCount } = projectsDetailsComponents;
  const { projects } = projectsDetailsIssues;
  const dispatch = useDispatch();

  const customEmpty = (
    <div className="flex flex-col items-center">
      <img src={noDataIcon} alt="No Data" />
      <p className="text-[#393939] text-[18px] lg:text-[20px]">No Data Found</p>
    </div>
  );
  
  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  useEffect(() => {
    dispatch(
      actionGetProjectsDetailsComponents({
        id: projectId,
        currentPageForComponent,
        itemsPerPageForComponent,
      })
    );
  }, [state, currentPageForComponent, itemsPerPageForComponent]);

  useEffect(() => {
    dispatch(
      actionGetProjectsDetailsIssues({
        id: projectId,
        currentPageForIssues,
        itemsPerPageForIssues,
      })
    );
  }, [state, currentPageForIssues, itemsPerPageForIssues]);

  const componentColumns = [
    {
      title: "Components",
      dataIndex: "name",
      fixed: "left",
      width: 200,
      align: "center",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Version",
      width: 100,
      align: "center",
      dataIndex: "version",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Group",
      width: 200,
      align: "center",
      dataIndex: "group",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Internal",
      width: 100,
      align: "center",
      dataIndex: "internal",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "License",
      width: 150,
      align: "center",
      dataIndex: "license",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    // {
    //   title: "Vulnerability",
    //   width: 100,
    //   align: "center",
    //   dataIndex: "Vulnerability",
    //   render: (text) => <span className="ml-2">{text || "-"}</span>,
    // },
    {
      title: "Risk Score",
      width: 100,
      align: "center",
      dataIndex: "lastInheritedRiskScore",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
  ];
  const issuesColumns = [
    {
      title: "Components",
      dataIndex: "name",
      fixed: "left",
      width: 150,
      align: "center",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Version",
      width: 100,
      align: "center",
      dataIndex: "version",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Group",
      width: 200,
      align: "center",
      dataIndex: "group",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Vulnerability",
      width: 150,
      align: "center",
      dataIndex: "vulnerability",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Severity",
      width: 150,
      align: "center",
      dataIndex: "severity",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Analyzer",
      width: 150,
      align: "center",
      dataIndex: "analyzer",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Attributed On",
      width: 150,
      align: "center",
      dataIndex: "attributedOn",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Analysis",
      align: "center",
      width: 250,
      ellipsis: true,
      dataIndex: "analysis",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Suppressed",
      width: 150,
      align: "center",
      dataIndex: "suppressed",
      render: (text) => <span className="ml-2">{text ? "True" : "False"}</span>,
    },
  ];

  const items = [
    {
      key: "1",
      label: "Severity",
      children: (
        <div>
          <Checkbox.Group onChange={onChange} className="flex flex-col gap-y-2">
            <Checkbox
              value="Critical"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Critical
            </Checkbox>
            <Checkbox
              value="High"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              High
            </Checkbox>
            <Checkbox
              value="Medium"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Medium
            </Checkbox>
            <Checkbox
              value="Low"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Low
            </Checkbox>
          </Checkbox.Group>
        </div>
      ),
    },
    {
      key: "2",
      label: "Priority Score",
      children: (
        <div>
          <p className="text-[12px] lg:text-[13px] text-filterOption-text font-normal">
            Scored between 0-1000
          </p>
          <Slider
            strokeColor="red"
            min={0}
            max={1000}
            value={scored}
            onChange={setScored}
          />
        </div>
      ),
    },
    {
      key: "3",
      label: "‘Fixed in’ Available",
      children: (
        <div>
          <Checkbox.Group onChange={onChange} className="flex flex-col gap-y-2">
            <Checkbox
              value="yes"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Yes
            </Checkbox>
            <Checkbox
              value="no"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              No
            </Checkbox>
          </Checkbox.Group>
        </div>
      ),
    },
    {
      key: "4",
      label: "Computed Fixability",
      children: (
        <div>
          <Checkbox.Group onChange={onChange} className="flex flex-col gap-y-2">
            <Checkbox
              value="fixable"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Fixable
            </Checkbox>
            <Checkbox
              value="partially_fixable"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Partially Fixable
            </Checkbox>
          </Checkbox.Group>
        </div>
      ),
    },
  ];

  const tabsItems = [
    {
      key: "1",
      label: "Component",
      children: (
        <div className="flex">
          <div className="w-[25%] border border-border bg-box-secondary p-2 lg:p-4">
            <div className="flex gap-x-3 items-center">
              <img src={filterIconBlue} alt="filter" />
              <p className="text-[13px] lg:text-[15px] font-montserratMedium text-text-primary">
                Filter
              </p>
            </div>
            <div className="mt-2 lg:mt-3">
              <Collapse
                defaultActiveKey={["1", "2", "3", "4"]}
                ghost
                items={items}
                bordered={false}
              />
            </div>
          </div>
          <div className="w-[75%] p-4 lg:p-8">
            <Table
              bordered
              pagination={false}
              scroll={{ x: "90vh" }}
              className="bg-transparent"
              columns={componentColumns}
              dataSource={components}
              // loading={getProjectsDetailsComponentsLoader}
              locale={{
                emptyText: customEmpty, // Apply the custom empty state
              }}
            />
            <div className="flex justify-end mt-2">
              <Pagination
                hideOnSinglePage
                current={currentPageForComponent}
                pageSize={itemsPerPageForComponent}
                total={totalProjectsComponent}
                onChange={(page, pageSize) => {
                  setCurrentPageForComponent(page); // Update current page
                  setItemsPerPageForComponent(pageSize); // Optionally, handle items per page change
                }}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Issues",
      children: (
        <div className="flex">
          <div className="w-[25%] border border-border bg-box-secondary p-2 lg:p-4">
            <div className="flex gap-x-3 items-center">
              <img src={filterIconBlue} alt="filter" />
              <p className="text-[13px] lg:text-[15px] font-montserratMedium text-text-primary">
                Filter
              </p>
            </div>
            <div className="mt-2 lg:mt-3">
              <Collapse
                defaultActiveKey={["1", "2", "3", "4"]}
                ghost
                items={items}
                bordered={false}
              />
            </div>
          </div>
          <div className="w-[75%] p-4 lg:p-8">
            <Table
              bordered
              pagination={false}
              scroll={{
                x: "90vh",
                // y: window.innerWidth < 1500 ? "49vh" : "61vh",
              }}
              className="bg-transparent"
              columns={issuesColumns}
              dataSource={projects}
              // loading={getProjectsDetailsIssuesLoader}
              locale={{
                emptyText: customEmpty, // Apply the custom empty state
              }}
            />
            <div className="flex justify-end mt-2">
              <Pagination
                hideOnSinglePage
                current={currentPageForIssues}
                pageSize={itemsPerPageForIssues}
                total={totalProjectsIssues}
                onChange={(page, pageSize) => {
                  setCurrentPageForIssues(page); // Update current page
                  setItemsPerPageForIssues(pageSize); // Optionally, handle items per page change
                }}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: "Services - End of Life",
      children: "Content of Services - End of Life",
    },
  ];

  const onChangeTabs = (key) => {
    console.log(key);
  };

  return (
    <>
      <Spin
        spinning={
          getProjectsDetailsIssuesLoader || getProjectsDetailsComponentsLoader
        }
      >
        <Row gutter={[16, 10]} justify="space-between">
          <Col xs={24} sm={24} md={24} lg={24} className="flex items-center">
            <div className="cursor-pointer mr-2" onClick={() => navigate(-1)}>
              <Image src={leftArrow} alt="image" preview={false} />
            </div>
            <p>
              <span className="text-[15px] font-montserratMedium text-[#757575]">
                Created Mon 8th Jul 2024 |{" "}
              </span>
              <span className="text-[15px] font-montserratMedium text-text-secondary">
                Snapshot by recurring test 17 hour ago |{" "}
              </span>
              <span className="text-[15px] font-montserratMedium text-primary">
                Retest now
              </span>
            </p>
          </Col>
          <Col xs={24} sm={12} md={12} lg={4}>
            <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-red-border">
              <h1 className="font-montserratMedium text-[17px]">
                {severityCount?.Critical || "0"}
              </h1>
              <p className="pt-2 text-[15px]">Critical</p>
            </div>
          </Col>

          <Col xs={24} sm={12} md={12} lg={4}>
            <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-pink-border">
              <h1 className="font-montserratMedium text-[17px]">
                {severityCount?.High || "0"}
              </h1>
              <p className="pt-2 text-[15px]">High</p>
            </div>
          </Col>

          <Col xs={24} sm={12} md={12} lg={4}>
            <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-blue-border">
              <h1 className="font-montserratMedium text-[17px]">
                {severityCount?.Medium || "0"}
              </h1>
              <p className="pt-2 text-[15px]">Medium</p>
            </div>
          </Col>

          <Col xs={24} sm={12} md={12} lg={4}>
            <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-green-border">
              <h1 className="font-montserratMedium text-[17px]">
                {severityCount?.Low || "0"}
              </h1>
              <p className="pt-2 text-[15px]">Low</p>
            </div>
          </Col>

          <Col xs={24} sm={12} md={12} lg={4}>
            <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-grey-border">
              <h1 className="font-montserratMedium text-[17px]">
                {severityCount?.Unassigned || "0"}
              </h1>
              <p className="pt-2 text-[15px]">Unassigned</p>
            </div>
          </Col>

          <Col xs={24} sm={12} md={12} lg={4}>
            <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-orange-border">
              <h1 className="font-montserratMedium text-[17px]">0</h1>
              <p className="pt-2 text-[15px]">Risk Score</p>
            </div>
          </Col>
        </Row>
        <div className="mt-2">
          <Tabs
            defaultActiveKey="1"
            items={tabsItems}
            onChange={onChangeTabs}
          />
        </div>
      </Spin>
    </>
  );
};

export default ProjectDetail;
