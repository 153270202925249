import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// import rightAngle from "../../assets/img/right-angle.svg";
// import leftAngle from "../../assets/img/left-angle.svg";

// Mock function to get the current year
const currentYear = new Date().getFullYear();

export const PrivateRoute = ({ component }) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/auth");
    }
  }, [navigate, token]);

  return component;
};

export const PublicRoute = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [navigate, token]);

  return (
    <div>
      <section>
        <Outlet />
      </section>
      {/* Content ends Here */}
    </div>
  );
};
