import { createSlice } from "@reduxjs/toolkit";
import {
  actionAddProject,
  actionGetDashboardAreaChartData,
  actionGetDashboardPieChartData,
  actionGetPortfolioVulnerabilitiesChartData,
  actionGetProjectsByOrgId,
  actionGetProjectsDetailsComponents,
  actionGetProjectsDetailsIssues,
  actionGetProjectsEndOfLife,
  actionGetProjectsFindings,
} from "../services/projectService";

const projectSlice = createSlice({
  name: "project",
  initialState: {
    addProjectLoader: false,
    getProjectsLoader: false,
    projects: [],
    projectsFindings: [],
    getProjectsfindingsLoader: false,
    projectsDetailsIssues: [],
    getProjectsDetailsIssuesLoader: false,
    projectsDetailsComponents: [],
    getProjectsDetailsComponentsLoader: false,
    totalProjects: "",
    totalProjectsComponent:"",
    totalProjectsIssues:"",
    projectsEndOfLife:[],
    getProjectsEndOfLifeLoader:false,
    getPortfolioVulnerabilitiesChartDataLoader:false,
    portfolioVulnerabilitiesChartData:"",
    getDashboardPieChartDataLoader:false,
    dashboardPieChartData:"",
  },
  reducers: {},
  extraReducers: (buider) => {
    buider

      // actionAddProject state
      .addCase(actionAddProject.pending, (state) => {
        state.addProjectLoader = true;
      })
      .addCase(actionAddProject.fulfilled, (state, action) => {
        state.addProjectLoader = false;
        state.projects.push(action.payload);
      })
      .addCase(actionAddProject.rejected, (state) => {
        state.addProjectLoader = false;
      })

      // actionGetProjectsByOrgId State
      .addCase(actionGetProjectsByOrgId.pending, (state) => {
        state.getProjectsLoader = true;
      })
      .addCase(actionGetProjectsByOrgId.fulfilled, (state, action) => {
        state.projects = action.payload?.projects;
        state.totalProjects = action.payload?.totalItems;
        state.getProjectsLoader = false;
      })
      .addCase(actionGetProjectsByOrgId.rejected, (state) => {
        state.getProjectsLoader = false;
      })

      // actionGetProjectsFindings State
      .addCase(actionGetProjectsFindings.pending, (state) => {
        state.getProjectsfindingsLoader = true;
      })
      .addCase(actionGetProjectsFindings.fulfilled, (state, action) => {
        state.projectsFindings = action.payload;
        state.getProjectsfindingsLoader = false;
      })
      .addCase(actionGetProjectsFindings.rejected, (state) => {
        state.getProjectsfindingsLoader = false;
      })

      // actionGetProjectsDetailsIssues State
      .addCase(actionGetProjectsDetailsIssues.pending, (state) => {
        state.getProjectsDetailsIssuesLoader = true;
      })
      .addCase(actionGetProjectsDetailsIssues.fulfilled, (state, action) => {
        state.projectsDetailsIssues = action.payload;
        state.totalProjectsIssues = action.payload?.totalItems;
        state.getProjectsDetailsIssuesLoader = false;
      })
      .addCase(actionGetProjectsDetailsIssues.rejected, (state) => {
        state.getProjectsDetailsIssuesLoader = false;
      })

      // actionGetProjectsDetailsComponents State
      .addCase(actionGetProjectsDetailsComponents.pending, (state) => {
        state.getProjectsDetailsComponentsLoader = true;
      })
      .addCase(
        actionGetProjectsDetailsComponents.fulfilled,
        (state, action) => {
          state.projectsDetailsComponents = action.payload;
          state.totalProjectsComponent = action.payload?.totalItems;
          state.getProjectsDetailsComponentsLoader = false;
        }
      )
      .addCase(actionGetProjectsDetailsComponents.rejected, (state) => {
        state.getProjectsDetailsComponentsLoader = false;
      })

      // actionGetProjectsEndOfLife State
      .addCase(actionGetProjectsEndOfLife.pending, (state) => {
        state.getProjectsEndOfLifeLoader = true;
      })
      .addCase(actionGetProjectsEndOfLife.fulfilled, (state, action) => {
        state.projectsEndOfLife = action.payload;
        state.getProjectsEndOfLifeLoader = false;
      })
      .addCase(actionGetProjectsEndOfLife.rejected, (state) => {
        state.getProjectsEndOfLifeLoader = false;
      })

      // actionGetPortfolioVulnerabilitiesChartData State
      .addCase(actionGetPortfolioVulnerabilitiesChartData.pending, (state) => {
        state.getPortfolioVulnerabilitiesChartDataLoader = true;
      })
      .addCase(actionGetPortfolioVulnerabilitiesChartData.fulfilled, (state, action) => {
        state.portfolioVulnerabilitiesChartData = action.payload;
        state.getPortfolioVulnerabilitiesChartDataLoader = false;
      })
      .addCase(actionGetPortfolioVulnerabilitiesChartData.rejected, (state) => {
        state.getPortfolioVulnerabilitiesChartDataLoader = false;
      })

       // actionGetDashboardPieChartData State
       .addCase(actionGetDashboardPieChartData.pending, (state) => {
        state.getDashboardPieChartDataLoader = true;
      })
      .addCase(actionGetDashboardPieChartData.fulfilled, (state, action) => {
        state.dashboardPieChartData = action.payload;
        state.getDashboardPieChartDataLoader = false;
      })
      .addCase(actionGetDashboardPieChartData.rejected, (state) => {
        state.getDashboardPieChartDataLoader = false;
      })

  },
});
export default projectSlice.reducer;
