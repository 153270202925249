import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import axios from "axios";
import { toast } from "sonner";

// Action to get all organizations
export const actionGetOrganization = createAsyncThunk(
  "actionGetOrganization",
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}organization/list/all-org`);
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

//Action add organization
export const actionAddOrganization = createAsyncThunk(
  "actionAddOrganization",
  async ({ values, handleCancel }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}organization/create`,
        values
      );
      console.log(response);
      const { status, data } = response;
      if (status === 201) {
        toast.success("Successfully Created", 5);
        handleCancel && handleCancel();
        return data;
      }
    } catch (error) {
      toast.error("Organization already exist", 5);
    }
  }
);


