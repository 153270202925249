import React, { useState, useEffect } from "react";
import listImage from "../../assets/img/listImage.svg";
import integrate from "../../assets/img/integrate.svg";
import bordersvg from "../../assets/img/bordersvg.svg";
import scan from "../../assets/img/scan.svg";
import quickly from "../../assets/img/quickly.svg";
import logo from "../../assets/img/logo.svg";
import line from "../../assets/img/line.svg";
import gitIconBlack from "../../assets/img/gitIconBlack.svg";
import gitIconWhite from "../../assets/img/gitIconWhite.svg";
import gitLabIcon from "../../assets/img/gitLabIcon.svg";
import bigline from "../../assets/img/bigline.svg";
import informationIconBlack from "../../assets/img/informationIconBlack.svg";
import informationIconWhite from "../../assets/img/informationIconWhite.svg";
import lightModeIcon from "../../assets/img/lightModeIcon.svg";
import darkModeIcon from "../../assets/img/darkModeIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionCallbackSetToken,
  actionLoginWithGithub,
} from "../../store/services/authService";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useTheme } from "../common/ThemeContext";

const Login = () => {
  const { isDarkMode, setIsDarkMode } = useTheme();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getGithubUrlLoader, getTokenLoader, token } = useSelector(
    (state) => state.auth
  );

  const handleLoginWithGithub = (event) => {
    event.preventDefault();
    dispatch(actionLoginWithGithub());
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const state = params.get("state");

    if (code && state) {
      dispatch(actionCallbackSetToken({ code, state, navigate }));
    }
  }, [dispatch]);

  // useEffect(() => {
  //   if (token) {
  //     navigate("/");
  //   }
  // }, [token, navigate]);

  return (
    <Spin spinning={getTokenLoader}>
      <div className="relative">
        {/* Dark Mode Toggle Button */}

        <div className="absolute top-4 right-4 flex border border-border rounded p-1 mr-3 gap-x-1">
          <div
            className={`w-[20px] h-[20px] md:w-[25px] md:h-[25px] rounded flex justify-center items-center cursor-pointer transition duration-500 ${
              isDarkMode ? "bg-transparent" : "bg-primary"
            }`}
            onClick={() => setIsDarkMode(false)}
          >
            <img src={lightModeIcon} alt="light" />
          </div>
          <div
            className={`w-[20px] h-[20px] md:w-[25px] md:h-[25px] rounded flex justify-center items-center cursor-pointer transition duration-500 ${
              isDarkMode ? "bg-white" : "bg-transparent"
            }`}
            onClick={() => setIsDarkMode(true)}
          >
            <img src={darkModeIcon} alt="dark" />
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row">
          <div className="px-4 py-6 md:py-0 h-auto md:min-h-screen w-full md:w-1/2 bg-lightbg dark:bg-darkbg bg-no-repeat bg-cover flex justify-center items-center">
            <div className="flex justify-start flex-col">
              <p className="text-[20px] md:text-[25px] lg:text-[30px] font-montserratSemiBold text-text-primary mb-2">
                Get more out of Neotrack
              </p>
              <p className="text-[13px] lg:text-[15px] text-text-secondary font-montserratRegular">
                Automate, manage and enforce code standards across your
                organization.
              </p>
              <img src={listImage} alt="image" className="my-2" />
              <div className="space-y-3 md:space-y-4 lg:space-y-5 mt-0 md:mt-5 lg:mt-7">
                <div className="p-3 bg-gradient-to-r from-list-bg to-transparent rounded-full">
                  <div className="flex items-center relative">
                    <div className="bg-white w-[40px] h-[40px] lg:w-[50px] lg:h-[50px] rounded-full flex items-center justify-center">
                      <img src={integrate} alt="icon" />
                    </div>
                    <p className="text-[14px] md:text-[16px] lg:text-[20px] text-text-primary ml-2 md:ml-4 font-montserratMedium">
                      Seamlessly integrate your projects
                    </p>
                    <div className="absolute left-11">
                      <img src={bordersvg} alt="icon" />
                    </div>
                  </div>
                </div>
                <div className="p-3 bg-gradient-to-r from-list-bg to-transparent rounded-full">
                  <div className="flex items-center relative">
                    <div className="bg-white w-[40px] h-[40px] lg:w-[50px] lg:h-[50px] rounded-full flex items-center justify-center">
                      <img src={scan} alt="icon" />
                    </div>
                    <p className="text-[14px] md:text-[16px] lg:text-[20px] text-text-primary ml-2 md:ml-4 font-montserratMedium">
                      Instantly scan for vulnerabillities
                    </p>
                    <div className="absolute left-11">
                      <img src={bordersvg} alt="icon" />
                    </div>
                  </div>
                </div>
                <div className="p-3 bg-gradient-to-r from-list-bg to-transparent rounded-full">
                  <div className="flex items-center relative">
                    <div className="bg-white w-[40px] h-[40px] lg:w-[50px] lg:h-[50px] rounded-full flex items-center justify-center">
                      <img src={quickly} alt="icon" />
                    </div>
                    <p className="text-[14px] md:text-[16px] lg:text-[20px] text-text-primary ml-2 md:ml-4 font-montserratMedium">
                      Fix quickly with a pull request
                    </p>
                    <div className="absolute left-11">
                      <img src={bordersvg} alt="icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 py-6 md:py-0 h-auto md:min-h-screen w-full md:w-1/2 bg-bg-secondary flex justify-center items-center flex-col gap-y-5">
            <img src={logo} alt="logo" />

            <div className="flex gap-x-1 items-center justify-center">
              <img src={line} alt="line" />
              <p className="text-[11px] lg:text-[13px] text-text-secondary font-montserratRegular">
                Sign in or Sign up
              </p>
              <img src={line} alt="line" />
            </div>

            <Link onClick={handleLoginWithGithub}>
              <div className="bg-text-primary w-[300px] lg:w-[330px] py-2 rounded-md flex gap-x-2 items-center justify-center">
                {getGithubUrlLoader ? (
                  <LoadingOutlined className="text-[20px] lg:text-[24px] text-[#707070]" />
                ) : (
                  <>
                    {isDarkMode ? (
                      <img src={gitIconBlack} alt="git" />
                    ) : (
                      <img src={gitIconWhite} alt="git" />
                    )}
                    <p className="text-text-third text-[14px] lg:text-[16px] font-montserratSemiBold">
                      Sign in with GitHub
                    </p>
                  </>
                )}
              </div>
            </Link>
            <Link to="/">
              <div className="bg-transparent w-[300px] lg:w-[330px] py-2 rounded-md flex gap-x-2 items-center justify-center border border-[#D8D8D8]">
                <img src={gitLabIcon} alt="git" />
                <p className="text-text-primary text-[14px] lg:text-[16px] font-montserratSemiBold">
                  Sign in with GitLab
                </p>
              </div>
            </Link>
            <div className="text-[13px] lg:text-[15px] font-medium text-center">
              <p className="space-x-2 font-montserratMedium">
                <span className="text-text-primary font-montserratRegular">
                  {" "}
                  Or Sign up with:{" "}
                </span>
                <span className="text-primary">
                  <a>Bitbucket</a>
                </span>
                <span className="text-primary">|</span>
                <span className="text-primary">
                  <a>Azure AD</a>
                </span>
                <span className="text-primary">|</span>
                <span className="text-primary">
                  <a>Docker ID</a>
                </span>
                <span className="text-primary">|</span>
                <span className="text-primary">
                  <a> Google</a>
                </span>
              </p>
            </div>
            <div>
              <img src={bigline} alt="line" />
            </div>
            <div className="flex gap-x-2 mt-3 md:mt-5 lg:mt-7">
              <p className="text-text-primary text-[12px] lg:text-[14px] font-montserratRegular">
                Log in with your company SSO
              </p>
              {isDarkMode ? (
                <img src={informationIconWhite} alt="information" />
              ) : (
                <img src={informationIconBlack} alt="information" />
              )}
            </div>
            <div>
              <p className="text-center text-text-secondary text-[10px] lg:text-[12px] font-montserratRegular">
                We will not make any use of the auth provider without your
                permission.
              </p>
              <p className="text-center text-text-secondary text-[10px] lg:text-[12px] font-montserratRegular">
                By logging in or signing up, you agree to abide by our policies,
                including our{" "}
                <span className="text-primary underline">
                  <a>Terms of Service</a>
                </span>{" "}
                and{" "}
                <span className="text-primary underline">
                  <a>Privacy Policy</a>
                </span>
              </p>
              <p className="text-center text-text-secondary text-[10px] lg:text-[12px] font-montserratRegular">
                Beta: v1.0
              </p>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Login;
