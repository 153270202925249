import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Dropdown,
  Empty,
  Form,
  Input,
  Modal,
  Pagination,
  Progress,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import searchIconGrey from "../../assets/img/searchIconGrey.svg";
import projectSearchIcon from "../../assets/img/projectSearchIcon.svg";
import projectUserIcon from "../../assets/img/projectUserIcon.svg";
import gitIconBlack from "../../assets/img/gitIconBlack.svg";
import gitIconWhite from "../../assets/img/gitIconWhite.svg";
import playIcon from "../../assets/img/playIcon.svg";
import paushIcon from "../../assets/img/paushIcon.svg";
import sbomFileIcon from "../../assets/img/sbomFileIcon.svg";
import cliIcon from "../../assets/img/cliIcon.svg";
import cicdIcon from "../../assets/img/cicdIcon.svg";
import uploadIcon from "../../assets/img/uploadIcon.svg";
import watchIcon from "../../assets/img/watchIcon.svg";
import deleteIcon from "../../assets/img/deleteIcon.svg";
import dropdownIcon from "../../assets/img/dropdownIcon.svg";
import filterIcon from "../../assets/img/filterIcon.svg";
import shortingIcon from "../../assets/img/shortingIcon.svg";
import packageIcon from "../../assets/img/packageIcon.svg";
import codeIcon from "../../assets/img/codeIcon.svg";
import noDataIcon from "../../assets/img/noDataIcon.svg";
import { useTheme } from "../common/ThemeContext";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import {
  actionAddProject,
  actionGetProjectsByOrgId,
  actionGetProjectsFindings,
} from "../../store/services/projectService";
const { Dragger } = Upload;
import { toast } from "sonner";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Link, useNavigate } from "react-router-dom";

const Project = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const [isScanOptionModalOpen, setIsScanOptionModalOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [sbomFile, setSbomFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sortingValue, setSortingValue] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filterOptionOpen, setFilterOptionOpen] = useState(false);
  const [addProjectForm] = Form.useForm();
  const { token, orgId } = useSelector((state) => state.auth);
  const {
    addProjectLoader,
    projects,
    getProjectsLoader,
    projectsFindings,
    getProjectsfindingsLoader,
    totalProjects,
  } = useSelector((state) => state.project);

  const dispatch = useDispatch();

  const chartOptions = {
    chart: {
      type: "area",
      backgroundColor: null, // Makes the chart background transparent
      height: 100,
    },
    title: {
      text: null, // Hide the chart title
    },
    xAxis: {
      visible: false, // Hide the X-axis completely
    },
    yAxis: {
      visible: false, // Hide the Y-axis completely
    },
    tooltip: {
      enabled: false, // Disable the tooltip
    },
    plotOptions: {
      area: {
        pointStart: 1,
        marker: {
          enabled: false, // Disable markers completely
        },
        states: {
          hover: {
            enabled: false, // Disable hover effect
          },
        },
      },
    },
    series: [
      {
        name: "Vulnerabilities",
        data: [100, 220, 220, 300, 320, 400], // Sample data for demonstration
        fillColor: "#CCDEFA",
        color: "#2B81F6",
      },
    ],
    legend: {
      enabled: false, // Disable the legend
    },
    credits: {
      enabled: false, // Disable the Highcharts.com credits
    },
  };

  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };
  const filterItems = [
    {
      label: (
        <div>
          <p className="text-black font-montserratSemiBold">- Show</p>
          <p className="text-border font-montserratMedium">Reset</p>
          <Checkbox.Group
            style={{
              width: "100%",
            }}
            onChange={onChange}
            className="flex flex-col pt-2 gap-y-1"
          >
            <Checkbox value="with_issues">With issues</Checkbox>
            <Checkbox value="without_issues">Without issues</Checkbox>
          </Checkbox.Group>
        </div>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div>
          <p className="text-black font-montserratSemiBold">- Integration</p>
          <p className="text-border font-montserratMedium">Reset</p>
          <Checkbox.Group
            style={{
              width: "100%",
            }}
            onChange={onChange}
            className="flex flex-col pt-2 gap-y-1"
          >
            <Checkbox value="BITBUCKET">Bitbucket Cloud App</Checkbox>
            <Checkbox value="CI_CLI">CI/CLI</Checkbox>
            <Checkbox value="github">GitHub</Checkbox>
          </Checkbox.Group>
        </div>
      ),
      key: "1",
    },
  ];

  const handleToggleDropdown = () => {
    setFilterOptionOpen((prevOpen) => !prevOpen);
  };
  const shortingItems = [
    {
      label: <p className="text-black font-montserratMedium">Ascending</p>,
      key: "asc",
    },
    {
      label: <p className="text-black font-montserratMedium">Descending</p>,
      key: "desc",
    },
  ];

  const showScanOptionModal = () => {
    setIsScanOptionModalOpen(true);
  };
  const handleChangeTags = (value) => {
    setSelectedItems(value);
  };

  const handleCancelScanOptionModal = () => {
    setIsScanOptionModalOpen(false);
    // setSelectedItems([])
  };

  const showAddProjectModal = () => {
    setIsAddProjectModalOpen(true);
    setIsScanOptionModalOpen(false);
  };

  useEffect(() => {
    if (isAddProjectModalOpen) {
      if (token) {
        const decodedToken = jwtDecode(token);
        setUserData(decodedToken);
      }
    }
  }, [isAddProjectModalOpen, token]);

  useEffect(() => {
    if (orgId) {
      dispatch(
        actionGetProjectsByOrgId({
          id: orgId,
          sort: sortingValue,
          currentPage,
          itemsPerPage,
        })
      );
    }
  }, [currentPage, itemsPerPage, token, orgId, sortingValue]);

  useEffect(() => {
    if (orgId) {
      dispatch(actionGetProjectsFindings(orgId));
    }
  }, [token, orgId]);

  const handleCancelAddProjectModal = () => {
    setIsAddProjectModalOpen(false);
    addProjectForm.resetFields();
    setFileList([]);
    setSbomFile(null);
  };

  const addFileProps = {
    name: "file",
    multiple: false,
    listType: "picture",
    action: null,
    fileList: fileList,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },

    beforeUpload: (file) => {
      setSbomFile(file);
      toast.success(`File selected: ${file.name}`, { duration: 5000 });
      return false;
    },
    onChange(info) {
      const { status, fileList } = info;

      if (status !== "uploading") {
        // Keep the fileList in its original form
        setFileList(fileList);
      }
      if (status === "done") {
        toast.success(`File uploaded successfully.`);
      } else if (status === "error") {
        toast.error(`File upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleAddProject = (value) => {
    const req = {
      projectVo: {
        name: value.name,
        groupOrUserName: userData?.tenant,
        type: "Type A",
        version: value.version,
        // description: value.description,
        // tagList: selectedItems,
        organizationUUID: orgId,
        userUUID: userData?.userId,
      },
      sbomFile,
    };

     dispatch(
      actionAddProject({
        req,
        handleCancel: handleCancelAddProjectModal,
      })
    );
  };

  const handleSortingClick = (e) => {
     setSortingValue(e.key);
  };

  return (
    <>
      <Spin spinning={getProjectsLoader || getProjectsfindingsLoader}>
        <Row gutter={[16, 24]} justify="space-between">
          <Col xs={24} sm={12} md={12} lg={6}>
            <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-blue-border">
              <h1 className="font-montserratMedium text-[17px]">
                {projectsFindings ? projectsFindings.projectsCount : "0"}
              </h1>
              <p className="pt-2 text-[15px]">Projects</p>
              {/* <div className="pt-2" style={{ width: "100%" }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              </div> */}
            </div>
          </Col>

          <Col xs={24} sm={12} md={12} lg={6}>
            <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-red-border">
              <h1 className="font-montserratMedium text-[17px]">
                {projectsFindings ? projectsFindings.riskScore : "0"}
              </h1>
              <p className="pt-2 text-[15px]">Risk Score</p>
              {/* <div className="pt-2" style={{ width: "100%" }}>
                <Progress percent={40} size="small" showInfo={false} />
              </div> */}
            </div>
          </Col>

          <Col xs={24} sm={12} md={12} lg={6}>
            <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-purple-border">
              <h1 className="font-montserratMedium text-[17px]">
                {projectsFindings ? projectsFindings.vulnerableProjects : "0"}
              </h1>
              <p className="pt-2 text-[15px]">Vulnerable Projects</p>
              {/* <div className="pt-2" style={{ width: "100%" }}>
                <Progress percent={70} size="small" showInfo={false} />
              </div> */}
            </div>
          </Col>

          <Col xs={24} sm={12} md={12} lg={6}>
            <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-pink-border">
              <h1 className="font-montserratMedium text-[17px]">
                {projectsFindings ? projectsFindings.supplyChainFindings : "0"}
              </h1>
              <p className="pt-2 text-[15px]">Supply Chain Findings</p>
              {/* <div className="pt-2" style={{ width: "100%" }}>
                <Progress percent={90} size="small" showInfo={false} />
              </div> */}
            </div>
          </Col>
        </Row>
        <div className="my-4 flex justify-between items-center">
          <div className="flex gap-x-2 items-center">
            <p className="text-[15px] font-montserratMedium text-text-primary">
              Targets
            </p>
            <div className="border border-border h-6 w-6 rounded-full flex justify-center items-center text-[12px] text-text-secondary">
              <p>3</p>
            </div>
            <div>
              <Button
                onClick={showScanOptionModal}
                type="primary"
                className="sm:px-[15px]  sm:py-[4px] px-[12px] py-[3px] text-[12px] font-montserratMedium"
              >
                Add Project
              </Button>
            </div>
          </div>
          <div>
            <div className="gap-x-2 flex items-center">
              <Input
                placeholder="Search"
                allowClear
                className="w-[300px] md:w-[350px] lg:w-[400px] py-2 border border-border hover:border-border bg-transparent hover:bg-transparent text-text-primary"
                prefix={
                  <img src={searchIconGrey} alt="icon" className="mr-2" />
                }
              />
              <Dropdown
                open={filterOptionOpen}
                className="ant-dropdown-custom"
                menu={{
                  items: filterItems, // Correctly pass the items in an object with `items` property
                }}
                trigger={["click"]}
              >
                <div onClick={handleToggleDropdown} className="cursor-pointer">
                  <div className="flex items-center px-2 gap-x-2 h-[40px] rounded-md border border-border">
                    <img src={filterIcon} alt="filter" />
                    <p className="text-text-secondary">Add Filter</p>
                    <img src={dropdownIcon} alt="filter" />
                  </div>
                </div>
              </Dropdown>
              <Dropdown
                className="ant-dropdown-custom"
                menu={{
                  items: shortingItems, // Correctly pass the items in an object with `items` property
                  onClick: handleSortingClick,
                }}
                trigger={["click"]}
              >
                <div>
                  <div className="flex items-center px-2 gap-x-2 h-[40px] rounded-md border border-border">
                    <img src={shortingIcon} alt="filter" />
                    <p className="text-text-secondary">Sort by order</p>
                    <img src={dropdownIcon} alt="filter" />
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="my-4">
          <div className="bg-box-secondary rounded-md border border-border">
            {projects && projects.length > 0 ? (
              projects.map((project, index) => (
                <div
                  key={index}
                  className={`flex justify-between items-center py-3 px-3 ${
                    index !== projects.length - 1
                      ? "border-b border-b-border"
                      : ""
                  }`}
                >
                  <div className="flex gap-x-2">
                    <p
                      onClick={() =>
                        navigate("/project-detail", {
                          state: { projectId: project.id },
                        })
                      }
                      className="text-text-primary text-[16px] lg:text-[18px] font-montserratMedium cursor-pointer"
                    >
                      {project?.name || "-"}
                    </p>
                    <div className="flex gap-x-2 items-center border border-border py-1 px-2 rounded-xl">
                      {isDarkMode ? (
                        <img src={gitIconWhite} />
                      ) : (
                        <img src={gitIconBlack} />
                      )}

                      <p className="text-text-secondary text-[12px] font-montserratRegular">
                        2
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-x-3 lg:gap-x-6">
                    <div className="border border-border rounded-[5px] flex">
                      <div className="flex text-text-secondary items-center border-r border-r-border text-[14px] font-montserratMedium">
                        <p className="py-1 px-2">
                          {project?.findings?.critical || "-"}
                        </p>
                        <p className="py-1">|</p>
                        <p className="py-1 px-2">C</p>
                      </div>
                      <div className="flex text-text-secondary items-center border-r border-r-border text-[14px] font-montserratMedium">
                        <p className="py-1 px-2">
                          {project?.findings?.high || "-"}
                        </p>
                        <p className="py-1">|</p>
                        <p className="py-1 px-2 text-[#CE5019]">H</p>
                      </div>
                      <div className="flex text-text-secondary items-center border-r border-r-border text-[14px] font-montserratMedium">
                        <p className="py-1 px-2">
                          {project?.findings?.medium || "-"}
                        </p>
                        <p className="py-1">|</p>
                        <p className="py-1 px-2 text-[#D68001]">M</p>
                      </div>
                      <div className="flex text-text-secondary items-center text-[14px] font-montserratMedium">
                        <p className="py-1 px-2">
                          {project?.findings?.low || "-"}
                        </p>
                        <p className="py-1">|</p>
                        <p className="py-1 px-2">L</p>
                      </div>
                    </div>
                    <Tooltip
                      title="EOL Findings"
                      placement="bottom"
                      color={isDarkMode ? "#494949" : "black"}
                    >
                      <div className="flex gap-x-2 items-center">
                        <div>
                          <img src={projectSearchIcon} alt="icon" />
                        </div>
                        <div className="flex text-[#757575] items-center text-[14px] font-montserratMedium">
                          <p>{project?.eol?.eolFindings || "0"}</p>
                        </div>
                      </div>
                    </Tooltip>
                    <Tooltip
                      title="Scan Status"
                      placement="bottom"
                      color={isDarkMode ? "#494949" : "black"}
                    >
                      <div className="flex gap-x-2 items-center">
                        <div>
                          <img src={projectUserIcon} alt="icon" />
                        </div>
                        <div className="flex text-[#757575] items-center text-[14px] font-montserratMedium">
                          <p>{project?.scanStatus || "-"}</p>
                        </div>
                      </div>
                    </Tooltip>
                    <div className="flex gap-x-2 items-center">
                      <div>
                        <img src={playIcon} alt="icon" />
                      </div>
                      <div>
                        <img src={paushIcon} alt="icon" />
                      </div>
                      <div>
                        <img src={watchIcon} alt="icon" />
                      </div>
                      <div className="text-[#bfbfbf]">|</div>
                      <div>
                        <img src={deleteIcon} alt="icon" />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="justify-center flex flex-col items-center py-4 lg:py-8">
                <div>
                  <img src={noDataIcon} alt="no data" />
                </div>
                <p className="text-[#393939] text-[22px] lg:text-[25px]">
                  No Data Found
                </p>
              </div>
            )}
          </div>
        </div>
        {projects && projects.length > 0 && (
          <div className="flex justify-end">
            <Pagination
              hideOnSinglePage
              current={currentPage}
              pageSize={itemsPerPage}
              total={totalProjects}
              onChange={(page, pageSize) => {
                setCurrentPage(page); // Update current page
                setItemsPerPage(pageSize); // Optionally, handle items per page change
              }}
            />
          </div>
        )}
        <Modal
          centered
          destroyOnClose={handleCancelAddProjectModal}
          onOk={handleCancelAddProjectModal}
          onCancel={handleCancelAddProjectModal}
          footer={false}
          width={650}
          open={isAddProjectModalOpen}
        >
          <h1 className="text-text-primary text-[20px] lg:text-[23px] font-font-montserratRegular text-center">
            Add Project
          </h1>
          <Form
            initialValues={{
              groupOrUserName: userData && userData.tenant,
            }}
            form={addProjectForm}
            onFinish={handleAddProject}
            layout="vertical"
            className="mt-[20px] mb-0"
          >
            <Form.Item
              name="name"
              label="Name"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: true,
                  message: "Please input project name!",
                },
              ]}
            >
              <Input
                style={{ background: "white" }}
                placeholder="Enter Project Name"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              />
            </Form.Item>

            {/* <Form.Item
              name="groupOrUserName"
              label="Group or User Name"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: true,
                  message: "Please input the group or user name!",
                },
              ]}
            >
              <Input
                style={{ background: "white" }}
                disabled
                defaultValue={userData?.tenant}
                placeholder="Enter Group or User Name"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              />
            </Form.Item> */}

            {/* <Form.Item
              name="type"
              label="Type"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: false,
                  message: "Please input the type!",
                },
              ]}
            >
              <Input
                style={{ background: "white" }}
                placeholder="Enter Type"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              />
            </Form.Item> */}
            <Form.Item
              name="version"
              label="Version"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: false,
                  message: "Please input the version!",
                },
              ]}
            >
              <Input
                style={{ background: "white" }}
                placeholder="Enter Version"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: false,
                  message: "Please input the type!",
                },
              ]}
            >
              {/* <Input
                style={{ background: "white" }}
                placeholder="Enter Type"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              /> */}
              <Input.TextArea
                placeholder="Enter Description"
                className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
              />
            </Form.Item>

            {/* <Form.Item
            name="highValue"
            label="High Value"
            className="mb-[12px]"
            size="medium"
            rules={[
              {
                required: false,
                message: "Please input the high value!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter High Value"
              className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
            />
          </Form.Item>

          <Form.Item
            name="mediumValue"
            label="Medium Value"
            className="mb-[12px]"
            size="medium"
            rules={[
              {
                required: false,
                message: "Please input the medium value!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Medium Value"
              className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
            />
          </Form.Item>

          <Form.Item
            name="lowValue"
            label="Low Value"
            className="mb-[12px]"
            size="medium"
            rules={[
              {
                required: false,
                message: "Please input the low value!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Low Value"
              className="py-2 border border-border !bg-transparent hover:bg-transparent text-text-primary"
            />
          </Form.Item> */}
            <Form.Item
              label="Tags"
              name="tagList"
              rules={[
                {
                  required: false,
                  message: "Please input tags!",
                },
              ]}
            >
              <Select
                open={true}
                className="remove-arrow tags-of-project"
                popupClassName="tag-select"
                multiple={true}
                showSearch={false}
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Enter tags"
                value={selectedItems}
                onChange={handleChangeTags}
                notFoundContent={null}
              />
            </Form.Item>
            <Form.Item
              name="sbomFile"
              label="Upload File"
              className="mb-[12px]"
              size="medium"
              rules={[
                {
                  required: false,
                  message: "Please upload file!",
                },
              ]}
            >
              <Dragger
                {...addFileProps}
                onRemove={(file) => {
                  setSbomFile(null);
                  setFileList((prev) =>
                    prev.filter((item) => item.uid !== file.uid)
                  );
                }}
                accept=".json"
                maxCount={1}
              >
                <div className="flex justify-center gap-x-2 py-2 lg:py-4">
                  <img src={uploadIcon} alt="upload icon" />
                  <p className="text-text-primary text-[#808080] text-[14px] font-montserratRegular">
                    Upload File
                  </p>
                </div>
              </Dragger>
            </Form.Item>

            <div className="flex justify-end mt-[40px]">
              <Form.Item className="mb-0">
                <button
                  onClick={() => handleCancelAddProjectModal()}
                  type="button"
                  className="rounded-[6px] text-grey-button border border-grey-button hover:text-grey-button min-h-[40px] min-w-[80px] text-[15px] font-montserratMedium"
                >
                  Cancel
                </button>
              </Form.Item>

              <div className="ml-4">
                <Form.Item className="mb-0">
                  <Button
                    loading={addProjectLoader}
                    type="primary"
                    className=" min-h-[40px] min-w-[120px] text-[15px] font-montserratMedium"
                    htmlType="submit"
                  >
                    Create Project
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Modal>
        <Modal
          centered
          onOk={() => handleCancelScanOptionModal()}
          onCancel={() => handleCancelScanOptionModal()}
          footer={false}
          width={850}
          open={isScanOptionModalOpen}
        >
          <h1 className="text-text-primary text-[20px] lg:text-[23px] font-montserratRegular text-center">
            Where do you want to scan ?
          </h1>
          <div className="py-3 flex gap-x-2 lg:gap-x-6">
            <div
              onClick={showAddProjectModal}
              className={`cursor-pointer p-2 w-[250px] h-[260px] lg:w-[280px] lg:h-[290px] border border-border rounded-[10px] flex flex-col items-center justify-center gap-y-2 ${
                isDarkMode ? "bg-[#0D0D0D]" : "bg-white"
              }`}
            >
              <img src={sbomFileIcon} alt="Upload SBOM File" />
              <p className="text-text-primary text-[18px] lg:text-[20px] font-montserratMedium">
                Upload SBOM File
              </p>
            </div>
            <div
              className={`cursor-pointer p-2 w-[250px] h-[260px] lg:w-[280px] lg:h-[290px] border border-border rounded-[10px] flex flex-col items-center justify-center gap-y-2 ${
                isDarkMode ? "bg-[#0D0D0D]" : "bg-white"
              }`}
            >
              <img src={cliIcon} alt="CLI" />
              <p className="text-text-primary text-[18px] lg:text-[20px] font-montserratMedium">
                CLI
              </p>
              <p className="text-text-primary text-center text-[13px] lg:text-[15px] font-montserratRegular">
                Scan locally with the Neotrack CLI and view findings in the
                Neotrack AppSec Platform.
              </p>
            </div>
            <div
              className={`cursor-pointer p-2 w-[250px] h-[260px] lg:w-[280px] lg:h-[290px] border border-border rounded-[10px] flex flex-col items-center justify-center gap-y-2 ${
                isDarkMode ? "bg-[#0D0D0D]" : "bg-white"
              }`}
            >
              <img src={cicdIcon} alt="CI/CD" />
              <p className="text-text-primary text-[18px] lg:text-[20px] font-montserratMedium">
                CI/CD
              </p>
              <p className="text-text-primary text-center text-[13px] lg:text-[15px] font-montserratRegular">
                Scan continuously using GitHub Actions, GitLab CI/CD, CircleCI
                or other CI providers
              </p>
            </div>
          </div>
        </Modal>
      </Spin>
    </>
  );
};

export default Project;
