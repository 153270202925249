import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import axios from "axios";
import { toast } from "sonner";

export const actionAddProject = createAsyncThunk(
  "actionAddProject",
  async ({ req, handleCancel }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("projectVo", JSON.stringify(req.projectVo));

      if (req.sbomFile instanceof File) {
        formData.append("sbomFile", req.sbomFile);
      }
      const response = await axios.post(
        `${BASE_URL}project/create/optionalsbom`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "*/*",
          },
        }
      );
      const { status, data } = response;
      if (response.status >= 200 && response.status < 300) {
        toast.success("Successfully Created", { autoClose: 5000 });
        handleCancel && handleCancel();
        return data;
      } else {
        return rejectWithValue("reject");
      }
    } catch (error) {
      // const errorMessage =
      //   error.response?.data?.error || error.message || "An error occurred";
      // toast.error(errorMessage, 5);
      toast.error("Project already exist", 5);
    }
  }
);

// Action to get projects by org id
export const actionGetProjectsByOrgId = createAsyncThunk(
  "actionGetProjectsByOrgId",
  async ({ id, sort, currentPage, itemsPerPage }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}organization/get-projects/${id}?page=${
          currentPage - 1
        }&size=${itemsPerPage}${sort ? `&sort=${sort}` : ""}`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// action to get project listing top card data
export const actionGetProjectsFindings = createAsyncThunk(
  "actionGetProjectsFindings",
  async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}organization/dashboard/sca-findings/${id}`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
      console.log(res, "res");
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// action to get project detail issues data
export const actionGetProjectsDetailsIssues = createAsyncThunk(
  "actionGetProjectsDetailsIssues",
  async ({ id, currentPageForIssues, itemsPerPageForIssues }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}project/get-project-details/${id}/issues?page=${
          currentPageForIssues - 1
        }&size=${itemsPerPageForIssues}`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
      console.log(res, "res");
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// action to get project detail component data
export const actionGetProjectsDetailsComponents = createAsyncThunk(
  "actionGetProjectsDetailsComponents",
  async ({ id, currentPageForComponent, itemsPerPageForComponent }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}project/get-project-details/${id}/mock-components?page=${
          currentPageForComponent - 1
        }&size=${itemsPerPageForComponent}&hasVulnerabilities=true&severity=false&onlyDirectDependencies=true&onlyOutdatedComponents=true`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// action to get dashboard bottom card data - end of life
export const actionGetProjectsEndOfLife = createAsyncThunk(
  "actionGetProjectsEndOfLife",
  async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}organization/dashboard/service-endoflife/${id}`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
      console.log(res, "res");
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// action to get dashboard area chart data 
export const actionGetPortfolioVulnerabilitiesChartData = createAsyncThunk(
  "actionGetPortfolioVulnerabilitiesChartData",
  async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}organization/graph-vulnerabilities/${id}`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// action to get dashboard pie chart data 
export const actionGetDashboardPieChartData = createAsyncThunk(
  "actionGetDashboardPieChartData",
  async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}organization/metrics-piechart/${id}`
      );
      const { data, status } = res;
      if (status === 200) {
        return data;
      } else if (status === 204) {
        return "";
      } else if (status === 404) {
        return "";
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);
