import { createBrowserRouter } from "react-router-dom";
import Login from "../components/auth/Login";
import Dashboard from "../components/dashboard/Dashboard";
import { NotFound } from "../components/common/NotFound";
import Root from "../components/common/Root";
import { PrivateRoute, PublicRoute } from "../components/helper/RouteHoc";
import Project from "../components/project/Project";
import MainDashboard from "../components/dashboard/MainDashboard";
import SupplyChain from "../components/supplyChain/SupplyChain";
import ProjectDetail from "../components/project/ProjectDetail";

export const router = createBrowserRouter([
  {
      element: <PublicRoute />,   // Public routes
    children: [
      {
        path: "/auth",
        element: <Login />,
      },
    ],
  },
  {
    element: <PrivateRoute component={<Root />} />, // Private routes (accessible only after login)
    children: [
      {
        path: "/",
        element: <MainDashboard />,
      },
      // {
      //   path: "/main-dashboard",
      //   element: <MainDashboard />,
      // },
      {
        path: "/project",
        element: <Project />,
      },
      {
        path: "/create-project",
        element: <Project />,
      },
      {
        path: "/supply-chain",
        element: <SupplyChain />,
      },
      {
        path: "/project-detail",
        element: <ProjectDetail />,
      },
    ],
  },
  {
    path: "*", // Fallback route for any undefined paths
    element: <NotFound />,
  },
]);
